import { useExperiment } from 'redux/experiment'
import { NumberParam, useQueryParam } from 'use-query-params'

/**
 * Home Page component
 * @returns JSX.Element
 */
const Home = () => {
  const { experiment } = useExperiment()
  const [currentFlow] = useQueryParam('flow', NumberParam)
  const PageComp = experiment?.[experiment?.flowOrder[currentFlow || 0]]

  return !!PageComp && <PageComp />
}

export default Home
